const config = {
  query: {
    state: 1,
    themetype: 1,
    user_id: null,
    keywords: null,
    cid: 0,
    content_id: 0,
  },
  vestUserList: [],
  // 按钮列表
  buttonList: Object.freeze([
    {
      label: '通过',
      type: 'primary',
      icon: 'el-icon-circle-check',
      eventName: 'handleTopAdopt',
    },
    {
      label: '不通过',
      type: 'danger',
      icon: 'el-icon-circle-close',
      eventName: 'handleTopFail',
    },
  ]),
  maxHeight: document.body.clientHeight - 346,
  stateList: Object.freeze([
    {
      id: 0,
      label: '全部',
    },
    {
      id: 1,
      label: '待审核',
    },
    {
      id: 2,
      label: '已通过',
    },
    {
      id: 3,
      label: '未通过',
    },
    {
      id: 4,
      label: '敏感词',
    },
    {
      id: 5,
      label: '用户删除',
    },
  ]),
  themeTypeList: Object.freeze([
    {
      id: 1,
      label: '轻分享圈',
    },
    {
      id: 2,
      label: '打卡圈',
    },
  ]),
  cTypeList: [
    {
      value: 1,
      type: 'show',
    },
    {
      value: 2,
      type: 'punch_card',
    },
    {
      value: 3,
      type: 'info',
    },
  ],
}

export default config
